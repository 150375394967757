import { ArrowPathIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import formJson from "src/components/Forms/FormJson/formJson";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { H4 } from "src/components/Shared/Text/Headers";
import PillEntry from "src/components/Workspaces/PillEntry";
import { isEmail } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import useFetch from "src/hooks/useFetch";

const FormSettings = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [tag, setTag] = useState("");
  const [prevData, setPrevData] = useState(formJson.settingsJson);
  const [settingsLoading, setSettingsLoading] = useState(true);

  const [settingsData, setSettingsData] = useState(formJson.settingsJson);
  const [settingsDisabled, setSettingsDisabled] = useState(false);

  const [datasetData, setDatasetData] = useState(formJson.settingsJson);
  const [datasetDisabled, setDatasetDisabled] = useState(false);

  const [isSyncingWithDomo, setIsSyncingWithDomo] = useState(false);

  const {
    response: { data: credentials },
  } = useFetch(`/credentials/all`, { query: { code: "domo" } });

  const getFormSettings = useCallback(async () => {
    try {
      if (id) {
        setSettingsLoading(true);
        const { data } = await apiRequest("get", searchParams.get("workspace_id") ? `/workspaces/:workspace_id/forms/:form_id/settings` : `/forms/:form_id/settings`, {
          params: { workspace_id: searchParams.get("workspace_id"), form_id: id },
        });
        if (data?.data) {
          setSettingsData({ ...data?.data?.settings });
          setDatasetData({ ...data?.data?.settings });
          setPrevData({ ...data?.data?.settings });
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      if (error.message !== "CanceledError: canceled") {
        navigate("/");
      }
    } finally {
      setSettingsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, id, navigate]);

  const handleOnSettingsSubmit = async () => {
    try {
      setSettingsDisabled(true);
      const { data } = await apiRequest(`PUT`, searchParams.get("workspace_id") ? `/workspaces/:workspace_id/forms/:form_id/settings` : `/forms/:form_id/settings`, {
        body: settingsData,
        params: { workspace_id: searchParams.get("workspace_id"), form_id: id },
      });
      toast.success(data.message);
      setPrevData((prevData) => ({ ...prevData, ...settingsData }));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSettingsDisabled(false);
    }
  };

  const handleOnDatasetSubmit = async () => {
    try {
      setDatasetDisabled(true);
      const { data } = await apiRequest(`PUT`, searchParams.get("workspace_id") ? `/workspaces/:workspace_id/forms/:form_id/dataset` : `/forms/:form_id/dataset`, {
        body: datasetData,
        params: { workspace_id: searchParams.get("workspace_id"), form_id: id },
      });
      toast.success(data.message);
      setPrevData((prevData) => ({ ...prevData, ...datasetData }));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDatasetDisabled(false);
    }
  };

  const handleOnManualDatasetSync = async () => {
    setIsSyncingWithDomo(true);
    try {
      await apiRequest(
        "GET",
        searchParams.get("workspace_id") ? `/workspaces/:workspace_id/forms/:form_id/push-dataset` : `/forms/:form_id/push-dataset`,
        { params: { workspace_id: searchParams.get("workspace_id"), form_id: id } },
        { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} },
      );
    } catch (error) {
    } finally {
      setIsSyncingWithDomo(false);
    }
  };

  const handleOnViewDataset = async () => {
    try {
      setDatasetDisabled(true);
      const { data } = await apiRequest(
        "GET",
        searchParams.get("workspace_id") ? `/workspaces/:workspace_id/forms/:form_id/view-dataset` : `/forms/:form_id/view-dataset`,
        { params: { workspace_id: searchParams.get("workspace_id"), form_id: id } },
        { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} },
      );
      window.open(data?.data?.url, "_blank");
    } catch (error) {
    } finally {
      setDatasetDisabled(false);
    }
  };

  const handleOnTagAdd = async () => {
    if (tag.trim() && isEmail(tag)) {
      if (!settingsData?.to_emails || settingsData?.to_emails?.findIndex((email) => email.toLowerCase() === tag.toLowerCase()) === -1) {
        setSettingsData((prevData) => ({ ...prevData, to_emails: [...(settingsData?.to_emails || []), tag] }));
        setTag("");
      }
    }
  };

  const handleOnTagRemove = async (index) => {
    settingsData?.to_emails.splice(index, 1);
    setSettingsData((prevData) => ({ ...prevData, to_emails: [...settingsData?.to_emails] }));
  };

  useEffect(() => {
    getFormSettings();
  }, [getFormSettings]);

  return (
    <div className="w-full">
      {settingsLoading ? (
        <Preloader />
      ) : (
        <>
          <EditContainer
            title="Email / Confirmation message"
            subtitle="Manage your all form settings from here"
            preview={{ text: "Email / Confirmation message" }}
            isLoading={settingsDisabled}
            onSuccess={handleOnSettingsSubmit}
            onCancel={() => setSettingsData(prevData)}>
            <div className="w-full max-w-[520px] space-y-4">
              <div className="relative w-full max-w-lg">
                <div className="mb-4 w-full">
                  <div className="flex gap-2">
                    <Input
                      inline={true}
                      label={"Receiver Email"}
                      wrapperClass={"!bg-white !font-normal"}
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                    />
                    <Button
                      version="secondary"
                      onClick={handleOnTagAdd}>
                      Add
                    </Button>
                  </div>
                </div>
                {settingsData?.to_emails?.length > 0 && (
                  <div className="flex flex-wrap items-center gap-2">
                    {settingsData?.to_emails?.map((email, index) => {
                      return (
                        <PillEntry
                          key={index}
                          index={index}
                          tag={email}
                          onDelete={handleOnTagRemove}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="relative w-full space-y-2">
                <H4
                  margin={false}
                  className="text-sm font-semibold uppercase">
                  After submission
                </H4>
                <div className="relative flex items-center gap-6">
                  <div className="relative w-full max-w-lg flex-shrink flex-grow">
                    <Input
                      inline={true}
                      label={"Confirmation message"}
                      wrapperClass={"!bg-white !font-normal"}
                      value={settingsData?.confirmation_message}
                      onChange={(e) => setSettingsData((prevData) => ({ ...prevData, confirmation_message: e.target.value }))}
                    />
                  </div>
                </div>
              </div>
            </div>
          </EditContainer>
          <EditContainer
            defaultOpen={true}
            title="Push response log to Domo Dataset"
            subtitle="Push your all response log to Domo Dataset from here"
            preview={{ text: "Push response log to Domo Dataset" }}
            isLoading={datasetDisabled}
            onSuccess={handleOnDatasetSubmit}
            onCancel={setDatasetData}>
            <div className="w-full max-w-[520px]">
              <div className="flex flex-col gap-4">
                <div className="relative w-full max-w-md">
                  <SelectMenu
                    inline={true}
                    label="DOMO credentials"
                    emptyListText={datasetData?.credential_name || "No listed items"}
                    defaultText={datasetData?.credential_name || "Select an option"}
                    startIndex={credentials?.findIndex((credential) => credential?._id?.toString() === datasetData?.credential_id?.toString())}
                    options={credentials?.map((credential) => ({ key: credential?.name, value: credential?._id, object: credential })) || [{ key: "Select credentials", value: -1 }]}
                    selectedOptionRendering="key"
                    searchableFields={["name"]}
                    setOption={(option) => {
                      setDatasetData((prevData) => ({ ...prevData, credential_id: option.value }));
                    }}
                  />
                </div>
                <div>
                  <Button
                    version="default"
                    className={classNames("text-grey-500 group flex !h-8 items-center gap-1 text-sm font-light")}
                    disabled={datasetDisabled || !datasetData?.dataset_id}
                    onClick={handleOnViewDataset}>
                    <p className={classNames(datasetDisabled || !datasetData?.dataset_id ? "" : "group-hover:text-highlightColor group-hover:underline")}>View dataset</p>
                    <ArrowTopRightOnSquareIcon className={classNames(datasetDisabled || !datasetData?.dataset_id ? "" : "group-hover:stroke-highlightColor", "h-4 w-4 stroke-1")} />
                  </Button>
                  <div className="flex gap-x-4">
                    <div className="w-full max-w-md">
                      <Input
                        name="dataset_id"
                        label="Dataset ID"
                        inline={true}
                        value={datasetData?.dataset_id}
                        onChange={(e) => setDatasetData((prevData) => ({ ...prevData, dataset_id: e.target.value }))}
                      />
                    </div>
                    <Button
                      disabled={!datasetData?.dataset_id || isSyncingWithDomo}
                      version="gray"
                      onClick={handleOnManualDatasetSync}>
                      <p className="pb-1">Sync</p>
                      <ArrowPathIcon
                        className={classNames("h-5 w-5", !isSyncingWithDomo ? "" : "animate-spin")}
                        role="status"
                      />
                    </Button>
                  </div>
                </div>
                <div className="relative">
                  <ToggleHeader
                    title="Schedule data push"
                    subtitle="This scheduler is set update your dataset every night at 3am."
                    position="left">
                    <Toggle
                      checked={datasetData?.sync_enabled}
                      onChange={(e) => {
                        setDatasetData((prevData) => ({ ...prevData, sync_enabled: !datasetData?.sync_enabled }));
                      }}
                    />
                  </ToggleHeader>
                </div>
              </div>
            </div>
          </EditContainer>
        </>
      )}
    </div>
  );
};

export default FormSettings;
