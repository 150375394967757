import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { addGroup, editGroupData } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import Modal from "src/components/Shared/Modal";
import { H3 } from "src/components/Shared/Text/Headers";
import WorkspaceGroupAuthorizeUserComponent from "src/components/Workspaces/Workspace/Groups/WorkspaceGroupAuthorizeUserComponent";
import WorkspaceImportUserModal from "src/components/Workspaces/Workspace/WorkspaceImportUserModal";
import PillEntry from "src/components/Workspaces/PillEntry";
import useFetch from "src/hooks/useFetch";
import MultiSelectObjectUsers from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObjectUsers";
import { v4 } from "uuid";
import InputWrapper from "src/components/Shared/Forms/InputWrapper";

const permissionJson = {
  _id: null,
  page_id: null,
  filters: [],
};

const GroupModalAdd = ({
  isOpen = false,
  closeModal = () => {},
  activeSSO,
  submitText,
  cancelText,
  title = "",
  secondaryTitle = "",
  defaultWorkspaceId = "",
  trustedAttributeArr = [],
  defaultStyles = {},
  defaultOptions = {},
  isPagePreview = false,
  children,
  onSubmit = () => {},
  removeSubmit = false,
  removeClose = false,
  setRefresh = () => {},
  importable = false,
  loadUsers = () => {},
  pagePreview = () => {},
  users = [],
  ...props
}) => {
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [tagList, setTagList] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState({ _id: -1, name: "None selected" });
  const [selectedUser, setSelectedUser] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [group, setGroup] = useState({});

  const [importModalOpen, setImportModalOpen] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successModalDisabled, setSuccessModalDisabled] = useState(false);

  const params = useParams();

  const {
    response: { data: workspaces },
    status: { done: workspacesLoaded },
  } = useFetch("/workspaces/list", { method: "post", data: { workspace_type: props.workspaceDetails?.workspace_type || "IFRAME_EMBED" } });

  const {
    response: { data: pages },
    status: { done: pagesLoaded },
  } = useFetch(`/pages/list${params.id ? "?workspace_id=" + params.id : ""}`);

  const handleSelectedUsers = (usersArray) => {
    let updatedSelectedUsers = usersArray.reduce((users, user) => (user.selected ? [...users, user.key] : users), []);
    setSelectedUser(updatedSelectedUsers);
  };

  const handleClearValues = () => {
    setGroup({});
    setName("");
    setTag("");
    setTagList([]);
    if (!defaultWorkspaceId) {
      setSelectedWorkspace({ _id: -1, name: "None selected" });
      setPageList([]);
      setUserList([]);
    }
    setSelectedUser([]);
    props.setIsOpen(false);
    props.setAddGroupStatus(false);
    props.setEditGroupStatus(false);
    props.setEditId(null);
  };

  useEffect(() => {
    if (defaultWorkspaceId && workspacesLoaded) {
      let defaultWorkspace = workspaces.find((workspace) => workspace._id === defaultWorkspaceId);
      if (defaultWorkspace) setSelectedWorkspace({ _id: defaultWorkspace._id, name: defaultWorkspace.name });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, workspacesLoaded, defaultWorkspaceId]);

  useEffect(() => {
    if (selectedWorkspace && Array.isArray(pages)) {
      setPageList(pages.filter((page) => page.workspace_id?._id === selectedWorkspace._id && page.page_type !== "LANDING_PAGE"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages?.length, pagesLoaded, selectedWorkspace]);

  useEffect(() => {
    if (props?.editId && props.groups.length) {
      const findGroup = props.groups.find((group) => group._id === props?.editId);
      setGroup({
        ...findGroup,
        permissions:
          findGroup.permissions.length > 0
            ? findGroup.permissions.map((permission) => {
                return {
                  ...permission,
                  id: v4(),
                  displayNameShow: permission?.page_alias ? true : false,
                  displayNameChanges: true,
                  filters: permission.filters.map((filter) => {
                    return {
                      ...filter,
                    };
                  }),
                };
              })
            : [{ id: v4(), ...permissionJson }],
      });
      setName(findGroup?.name);
      setTagList(findGroup?.tags || []);
      setSelectedWorkspace(findGroup?.workspace_id);
      setPageList(pages.filter((pg) => pg.workspace_id?._id === findGroup?.workspace_id?._id && pg.page_type !== "LANDING_PAGE"));
      setUserList(users.filter((user) => findGroup?.workspace_id?.userIds?.find((id) => id?.id?.toString() === user?._id?.toString())));
      setSelectedUser(findGroup?.user_id);
    } else {
      handleClearValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.editId, props?.groups]);

  useEffect(() => {
    if (props.workspaceDetails?._id) {
      if (users?.length > 0) {
        setUserList(users);
      }
      if (pages?.length > 0) {
        setPageList(pages.filter((pg) => pg.workspace_id?._id?.toString() === props.workspaceDetails?._id?.toString() && pg.page_type !== "LANDING_PAGE"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, pages, props.workspaceDetails?._id]);

  const {
    response: { data: operators },
  } = useFetch("/operators/list");

  const updateWorkspace = async (updatedWorkspace) => {
    setSelectedWorkspace(updatedWorkspace);
    setPageList(pages.filter((pg) => pg.workspace_id?._id?.toString() === updatedWorkspace._id?.toString() && pg.page_type !== "LANDING_PAGE"));
    setSelectedUser([]);
    setUserList(users.filter((user) => updatedWorkspace?.userIds?.find((id) => id?._id?.toString() === user?._id?.toString())));
    setGroup((prevData) => ({ ...prevData, permissions: [] }));
  };

  const createGroup = async (importStatus = undefined) => {
    try {
      setSuccessModalDisabled(true);
      const data = await props.addGroup({
        name: name,
        defaultWorkspaceId,
        workspace_id: selectedWorkspace._id,
        user_id: selectedUser,
        permissions: group?.permissions,
        tags: tagList,
        auth_token: props.workspaceDetails?.auth_token,
        importStatus,
        sendWelcomeEmail,
        password,
        confirmPassword,
      });
      handleClearValues();

      setRefresh(selectedUser);
      setSuccessModalDisabled(false);
      toast.success(data.message);
    } catch (error) {
      setSuccessModalDisabled(false);
      toast.error(error.message);
    }
  };

  const editGroup = async () => {
    try {
      const data = await props.editGroupData({
        id: group?._id,
        name: name,
        workspace_id: selectedWorkspace._id,
        user_id: selectedUser,
        permissions: group?.permissions,
        tags: tagList,
        auth_token: props.workspaceDetails?.auth_token,
      });
      handleClearValues();

      setRefresh(selectedUser);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const groupModalSubmit = async (importStatus = undefined) => {
    try {
      if (importable && typeof importStatus !== "boolean" && selectedUser.length && props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
        if (users.filter((user) => selectedUser.includes(user.id || user._id) && !user.portalUser).length) {
          if (props.addGroupStatus) {
            // group validation
            try {
              const groupValRes = await apiRequest("post", "/groups-validation", {
                body: {
                  name: name,
                  workspace_id: selectedWorkspace._id,
                  user_id: selectedUser,
                  permissions: group?.permissions,
                  tags: tagList,
                  auth_token: props.workspaceDetails?.auth_token,
                },
              });

              if (groupValRes.data && groupValRes.data.status !== 200) {
                toast.error(groupValRes.data.message);
                return;
              }
            } catch (error) {
              // toast
              toast.error(error.message);
              return;
            }
          }

          props.setIsOpen(false);
          setImportModalOpen(true);
          return;
        }
      }

      if (props.addGroupStatus) {
        await createGroup(importStatus);

        if (props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
          apiRequest("post", `/workspaces/${props.workspaceDetails._id}/domo-data-count`, { body: {} });
        }

        loadUsers();
        setImportModalOpen(false);
        closeModal();
      } else if (props.editGroupStatus) {
        await editGroup();
        setImportModalOpen(false);
        closeModal();
      }
    } catch (error) {
      // console.dir("ERROR:", error);
    }

    return;
  };

  const addNewPermission = (e, index) => {
    if (!group?.permissions?.find((item, i) => i === index)) {
      setGroup((prevData) => ({ ...prevData, permissions: [...(prevData?.permissions || []), { id: v4(), ...permissionJson }] }));
    } else {
      setGroup((prevData) => ({ ...prevData, permissions: prevData?.permissions?.map((item, i) => (i !== index ? item : e)) }));
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(group?.permissions, result.source.index, result.destination.index);
    setGroup((prevData) => ({ ...prevData, permissions: [...items] }));
  };

  const handleTag = async (e) => {
    if ((e.code === "Enter" || e.code === "Comma" || e.submit === true) && tag.trim()) {
      e.preventDefault();
      if (tagList.findIndex((tg) => tg.toLowerCase() === tag.toLowerCase()) === -1) {
        const tags = [...tagList, tag];
        setTagList(tags);
        setTag("");
      }
    }
  };

  const removeTag = async (index) => {
    tagList.splice(index, 1);
    setTagList([...tagList]);
  };

  return (
    <>
      <Modal
        size="xxl"
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        onCancel={() => {
          if (!isPagePreview) {
            props.setIsOpen(!isOpen);
            props.setEditId(null);
          }
        }}
        onClose={() => {
          props.setIsOpen(!isOpen);
          props.setEditId(null);
        }}
        onSuccess={() => {
          onSubmit();
          groupModalSubmit();
        }}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalDisabled,
          ...defaultOptions,
        }}
        defaultStyles={{
          ...defaultStyles,
          overFlowYVisible: true,
        }}>
        <div className="relative w-full space-y-4">
          <div className="relative mb-4 w-full">
            <Input
              name="name"
              inline={true}
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          {activeSSO?.isSSOActive && (
            <div className="mb-4 space-y-4">
              <div className="w-full">
                <InputWrapper
                  inline={true}
                  type="text"
                  autoComplete="off"
                  name="tag-name"
                  label="Tags"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  onKeyDown={handleTag}
                  onRemove={() => setTagList([])}
                />
              </div>
              {tagList.length > 0 && (
                <div className="flex flex-wrap items-center gap-2">
                  {tagList.map((tag, index) => {
                    return (
                      <PillEntry
                        index={index}
                        tag={tag}
                        onDelete={removeTag}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {!defaultWorkspaceId && (
            <div className="relative mb-4 w-full">
              <Select
                label={"Workspace"}
                inline={true}
                options={workspacesLoaded ? workspaces : []}
                selected={selectedWorkspace}
                setSelected={(e) => updateWorkspace(e)}
              />
            </div>
          )}

          <div className="relative w-full pt-1">
            <div className="relative mb-4 w-full border-b border-gray-200 pb-8">
              <H3 caption="Assign all users that should have access to these specific pages and links.">Assign users</H3>
              <MultiSelectObjectUsers
                label={"Assign users"}
                inline={true}
                usersLoaded={true}
                users={userList}
                selectedUserIds={selectedUser}
                handleSelectedUsers={handleSelectedUsers}
                strictOnChangeMode={false}
              />
            </div>
            {props.workspaceDetails?.workspace_type !== "JWT_FULL_EMBED" && (
              <div className="scrollbar max-h-[calc(100vh-420px)] overflow-auto pr-2">
                <div className="relative mb-4 flex w-full ">
                  <H3 caption="Assign all users that should have access to these specific pages and links.">Add pages</H3>
                  <div className="flex items-center justify-end whitespace-nowrap md:w-1/3">
                    <Button
                      disabled={pageList.length === 0}
                      version="secondary"
                      onClick={addNewPermission}>
                      Add Page
                    </Button>
                  </div>
                </div>
                <div className="relative">
                  <WorkspaceGroupAuthorizeUserComponent
                    group={group}
                    onDragEnd={onDragEnd}
                    permissions={group?.permissions}
                    pageOptions={pageList}
                    operatorOptions={operators}
                    setGroup={setGroup}
                    trustedAttributeArr={trustedAttributeArr}
                    workspaceDetails={props.workspaceDetails}
                    workspaceId={props.workspaceDetails?._id}
                    viewOnly={false}
                    addPermission={(e, index) => {
                      addNewPermission(e, index);
                    }}
                    removePermission={(e, index) => {
                      setGroup((prevData) => ({ ...prevData, permissions: prevData?.permissions?.filter((p, i) => i !== index) }));
                    }}
                    authorizeUserComponentAccessPermission={true}
                    hideAliasManageButton={true}
                    className={"!left-0"}
                    pagePreview={pagePreview}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <WorkspaceImportUserModal
        isImportModalOpen={importModalOpen}
        setIsImportModalOpen={setImportModalOpen}
        selectedUsers={selectedUser}
        workspaceUsers={users}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        onSuccess={(users, status) => groupModalSubmit(status)}
        workspaceDetailsData={props.workspaceDetails}
        hideCrossIcon={false}
        disableStatus={successModalDisabled}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { addGroup, editGroupData })(GroupModalAdd);
